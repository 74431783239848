import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./index.css";

import Button from "../Button";

const TryFreeTrial: React.FC = () => (
  <div className="try-free-trial-wrapper px-3 md:px-0">
    <div className="try-free-trial text-3xl sm:text-4xl md:text-5xl ">
      deeple ระบบจัดการร้านค้าออนไลน์
      <br />
      ที่รู้ใจทุกธุรกิจบน Social Commerce
    </div>
    <div className="try-free-trial-button">
      <a href="https://console.deeple.ai/register">
        <Button className="px-10 text-xl" variant="primary" size="medium">
          สมัครใช้งานฟรี
        </Button>
      </a>
    </div>

    <StaticImage
      className="mx-auto"
      src="../../../static/image/platform.png"
      alt={"Platform"}
      placeholder="blurred"
    />
  </div>
);

export default TryFreeTrial;
